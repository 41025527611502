<template>
  <Default>
    <div class="usuarios">
      <banner
        :fondo="newLocal"
        titulo="Registrar local"
        descripcion=""
      ></banner>

      <section class="main__content">
        <div class="card">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              @click="$router.go(-1)"
            >
              Atrás
            </button>
          </div>

          <div class="card-body">
            <form @submit.prevent="crear()">
              <div class="row">
                <div class="col-12 col-md-9">
                  <div class="form-row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="nomLocal">Nombre</label>
                        <input
                          type="text"
                          id="nomLocal"
                          class="form-control"
                          v-model="nomLocal"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="formato">Formato</label>
                        <!-- <v-select
                        :options="formatosDisponible"
                        label="text"
                        id="formato"
                        :clearable="false"
                        :reduce="(e) => e.text"
                        v-model="formato"
                      >
                      </v-select> -->
                        <input
                          type="text"
                          id="formato"
                          class="form-control"
                          v-model="formato"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="direccion">Dirección</label>
                        <input
                          type="text"
                          id="direccion"
                          class="form-control"
                          v-model="direccion"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="personal-atencion"
                          >Personal de atención</label
                        >
                        <input
                          type="text"
                          id="personal-atencion"
                          class="form-control"
                          v-model="personalAtencion"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="celular">Nro. celular</label>
                        <input
                          type="number"
                          id="celular"
                          class="form-control"
                          v-model="celular"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="emailContacto">Correo electrónico</label>
                        <input
                          type="text"
                          id="emailContacto"
                          class="form-control"
                          v-model="emailContacto"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="email">Departamento</label>

                        <select
                          id="localId"
                          class="form-control"
                          v-model="DeparCodi"
                          @change="getProvincias()"
                        >
                          <option
                            v-for="(item, index) in departamentos"
                            :key="index"
                            :value="item.value"
                            >{{ item.text }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="celular">Provincia</label>
                        <select
                          id="localId"
                          class="form-control"
                          v-model="ProvCodi"
                          @change="getDistritos()"
                        >
                          <option
                            v-for="(item, index) in provincias"
                            :key="index"
                            :value="item.value"
                            >{{ item.text }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="email">Distrito</label>
                        <select
                          id="localId"
                          class="form-control"
                          v-model="DistCodi"
                        >
                          <option
                            v-for="(item, index) in distritos"
                            :key="index"
                            :value="item.value"
                            >{{ item.text }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <vue-dropzone
                      ref="dropzoneImage"
                      id="dropzone"
                      :options="dropzoneOptions"
                      @vdropzone-complete="asignarImagen"
                      v-show="mostrarDropzone"
                    ></vue-dropzone>

                    <section v-if="preview">
                      <div class="preview">
                        <img
                          :src="preview"
                          alt="Imagen de usuario"
                          class="img-fluid"
                        />
                      </div>

                      <div
                        class="d-flex justify-content-between align-items-center mt-3"
                      >
                        <a
                          href=""
                          @click.prevent="removeImage()"
                          class="small text-danger d-inline-block mb-2"
                          >Cambiar imagen</a
                        >
                      </div>
                    </section>
                  </div>
                </div>

                <div
                  class="col-12 
                d-flex justify-content-end mr-md-5 form-group text-right"
                >
                  <button
                    type="submit"
                    class="btn btn-info px-4"
                    :disabled="loading || $v.$invalid"
                  >
                    <template v-if="loading">
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Cargando...</span>

                      <span class="d-inline-block ml-2">Cargando</span>
                    </template>

                    <span v-else>Registrar Local</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </Default>
</template>

<script>
import Default from "@/layouts/default.vue";
import GetDepartamentos from "@/apollo/queries/ubicaciones/GetDepartamentos.gql";
import GetProvincias from "@/apollo/queries/ubicaciones/GetProvincias.gql";
import GetDistritos from "@/apollo/queries/ubicaciones/GetDistritos.gql";

import CreateLocal from "@/apollo/mutations/locales/CreateLocal.gql";

import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");

import Banner from "@/components/global/Banner";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { formatosDisponible } from "../../../.env.js";

import newLocal from "@/assets/banner/newCompany.jpg";

export default {
  data() {
    return {
      departamentos: [],
      DeparCodi: null,
      provincias: [],
      ProvCodi: null,
      distritos: [],
      DistCodi: null,
      nomLocal: null,
      formatosDisponible: formatosDisponible,
      personalAtencion: "",
      formato: "",
      direccion: null,
      emailContacto: null,
      celular: null,
      imagen: null,
      newLocal: newLocal,
      mostrarDropzone: true,
      preview: null,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 145,
        thumbnailHeight: 145,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastre o haga click aquí</p>
                              <p class="form-text">Archivos permitidos: .jpg, .jpeg, .png</p>
                            `,
      },
      loading: false,
    };
  },
  mixins: [validationMixin],
  mounted() {
    this.getDepartamentos();
  },
  components: {
    Banner,
    Default,
    vueDropzone,
  },
  watch: {
    emailContacto(el) {
      this.emailContacto = this.emailContacto.toLowerCase();
    },
  },
  methods: {
    getDepartamentos() {
      return new Promise((resolve) => {
        this.$apollo
          .query({
            query: GetDepartamentos,
          })
          .then((response) => {
            this.departamentos[0] = {
              value: null,
              text: "Seleccione",
            };

            let datosOrganizados = response.data.GetDepartamentos.map(
              (item) => {
                return {
                  value: item.DeparCodi,
                  text: item.DeparNom,
                };
              }
            );

            this.departamentos = this.departamentos.concat(datosOrganizados);

            this.DeparCodi = this.departamentos[0].value;

            resolve();
          });
      });
    },
    getProvincias() {
      return new Promise((resolve) => {
        this.provincias = [];

        let DepCode = this.DeparCodi;

        this.$apollo
          .query({
            query: GetProvincias,
            variables: {
              DepCode,
            },
          })
          .then((response) => {
            this.provincias = response.data.GetProvincias.map((item) => {
              return {
                value: item.ProvCodi,
                text: item.ProvNom,
              };
            });

            this.ProvCodi = this.provincias[0].value;

            this.getDistritos(this.ProvCodi);

            resolve();
          });
      });
    },
    getDistritos() {
      this.distritos = [];

      let ProCode = this.ProvCodi;

      this.$apollo
        .query({
          query: GetDistritos,
          variables: {
            ProCode,
          },
        })
        .then((response) => {
          this.distritos = response.data.GetDistritos.map((item) => {
            return {
              value: item.DistCodi,
              text: item.DistNom,
            };
          });

          this.DistCodi = this.distritos[0].value;
        });
    },
    asignarImagen(file) {
      // Crea una url local para mostrar imagen previa
      this.preview = URL.createObjectURL(file);

      this.imagen = file;

      this.$refs.dropzoneImage.disable();

      this.mostrarDropzone = false;
    },
    removeImage() {
      this.preview = "";

      this.$refs.dropzoneImage.removeAllFiles();
      this.$refs.dropzoneImage.enable();

      this.mostrarDropzone = true;
    },
    crear() {
      this.loading = true;

      let {
        DeparCodi,
        ProvCodi,
        DistCodi,
        nomLocal,
        direccion,
        emailContacto,
        personalAtencion,
        formato,
        celular,
        imagen,
      } = this;

      let input = {
        DeparCodi,
        ProvCodi,
        DistCodi,
        personalAtencion,
        formato,
        nomLocal,
        direccion,
        emailContacto,
        celular,
      };

      this.$apollo
        .mutate({
          mutation: CreateLocal,
          variables: {
            input,
            imagen,
          },
        })
        .then((response) => {
          this.loading = false;

          let { nomLocal } = response.data.CreateLocal;

          this.$toast.success(`${nomLocal} fue registrado`, {
            // override the global option
            position: "top-right",
          });

          this.$router.push({ name: "locales" });
        });
    },
  },
  validations: {
    DeparCodi: { required },
    ProvCodi: { required },
    DistCodi: { required },
    nomLocal: { required },
    formato: { required },
    direccion: { required },
    emailContacto: { email },
  },
};
</script>

<style lang="scss"></style>
